import { useEffect,useState,Fragment } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import {auth} from "../config/firebase"
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import AddRidePage from "../createRide/AddRidePage"
import PreviousRidesPage from "../viewRides/PrevRides"
import UpcomingRidesPage from "../viewRides/UpcomingRides"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PrevRidesSmall from '../viewRides/PrevRidesSmall';
import UpcomingRidesSmall from '../viewRides/UpcomingRidesSmall';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyIcon from '@mui/icons-material/Key';
import Dialog from '@mui/material/Dialog'; 
import DialogTitle from '@mui/material/DialogTitle'; 
import DialogContent from '@mui/material/DialogContent'; 
import TextField from '@mui/material/TextField'; 
import DialogActions from '@mui/material/DialogActions'; 
import Button from '@mui/material/Button';
import {
  EmailAuthProvider,
  updatePassword ,
  reauthenticateWithCredential,
} from 'firebase/auth'

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const handleLogout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error('Error signing out:', error.message);
  }
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AdminDashboard() {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [showComponent, setShowComponent] = useState("summary");
  
  const handleChangeComponent = (component) => {
    setShowComponent(component);
  };

  const [selectedComponent, setSelectedComponent] = useState(null);
  
  const handleSelectedComponent = (component) => {
    setSelectedComponent(component);
  };
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleOpenDialog = () => {
    setAnchorEl(null);
    setCurrentPassword('');
    setPassword('');
    setConfirmPassword('');
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePassword = async () => {
    if (password.length < 6) {
      alert('HATA: Şifre en az 6 karakter olmalı!');
      return;
    }
    if (password !== confirmPassword) {
      alert('HATA: Şifreler eşleşmiyor!');
      return;
    }
    try {
      const user = auth.currentUser;
      if (user) {
        const credential = EmailAuthProvider.credential(
          user.email, 
          currentPassword
        );
        await reauthenticateWithCredential(
          user, 
          credential
        )

        await updatePassword(
          user,
          password
        );

        alert('Şifre Değiştirildi');
        setOpenDialog(false);
        handleLogout();
      }
    } catch (error) {
      alert(error.message);
    }
  }


  function Summary() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Upcoming Rides */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <UpcomingRidesSmall handleChangeComponent={handleChangeComponent} />
            </Paper>
          </Grid>
          
          {/* Previous Rides */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <PrevRidesSmall handleChangeComponent={handleChangeComponent}/>
            </Paper>
          </Grid>
          
        </Grid>
        
      </Container>
    );
  }

  const setComponent = () =>{
    switch(showComponent){
      case "summary":
         handleSelectedComponent(<Summary/>);
         break;

      case "addRide":
         handleSelectedComponent(<AddRidePage/>);
         break;

      case "upcomingRides":
         handleSelectedComponent(<UpcomingRidesPage/>);
         break;

      case "previousRides":
         handleSelectedComponent(<PreviousRidesPage/>);
         break;

      default:
        throw new Error('Unknown component: ' + showComponent);
    }
  }

  useEffect(() => {
    setComponent();
  }, [showComponent]);

  return (
    
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
            <img src="https://firebasestorage.googleapis.com/v0/b/ytu-travel-app.appspot.com/o/logo.png?alt=media&token=73d40582-38f9-44ec-83e4-70f3e148c796" alt="logo" border="0" width="80" height="25" />
             
              Yolculuk Takip Sistemi | <b>MUHASEBE PANELİ</b>
            </Typography>
            
            <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            size='large'
            onClick={handleMenuClick}
            style={{ color: 'white' }}
            >
              <AccountBoxIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {/*sidebar*/}
            <Fragment>
              <ListItemButton onClick={()=>{handleChangeComponent("summary")}}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Ana Sayfa" />
              </ListItemButton>
              <ListItemButton onClick={()=>{handleChangeComponent("addRide")}}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Yeni Yolculuk" />
              </ListItemButton>
              <ListItemButton onClick={()=>{handleChangeComponent("upcomingRides")}}>
                <ListItemIcon>
                  <UpdateIcon />
                </ListItemIcon>
                <ListItemText primary="Gelecek Yolculuklar" />
              </ListItemButton>
              <ListItemButton onClick={()=>{handleChangeComponent("previousRides")}}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Geçmiş Yolculuklar" />
              </ListItemButton>
            </Fragment>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {/*components render here*/}

          {selectedComponent}
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem disabled >
          <Typography variant="subtitle1" gutterBottom>
            {auth.currentUser.email}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          <ListItemIcon>
            <KeyIcon fontSize="small" />
          </ListItemIcon>
          Şifre Değiştir
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Çıkış Yap
        </MenuItem>
      </Menu>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Şifre Değiştir</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Şifre"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Yeni Şifre"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="confirm-password"
            label="Yeni Şifreyi Onayla"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            İptal
          </Button>
          <Button onClick={handleChangePassword} variant='contained' color="primary">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
