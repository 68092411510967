import * as React from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, doc, setDoc, updateDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import InputLabel from '@mui/material/InputLabel';
import TablePagination from '@mui/material/TablePagination';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./tableStyle.css";
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function UsersPage() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleOpenUpdate = (id,name,role,phoneNumber,address) => {
        setUpdateId(id);
        setName(name);
        setRole(role);
        setPhone(phoneNumber);
        setAdress(address);
        setOpenUpdate(true);
    }
    const handleCloseUpdate = () => {
        setUpdateId("");
        setName("");
        setRole("user");
        setPhone("");
        setAdress("");
        setOpenUpdate(false);
    }
    
    const [searchTerm, setSearchTerm] = React.useState("");
    const [userType, setUserType] = React.useState("Hepsi");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        setPage(0);
      }, [searchTerm, userType]);

    const [users, setUsers] = React.useState([]);
    const fetchUsers = async () => {
        const db = getFirestore();
        const userCollection = collection(db, "users");
        const userSnapshot = await getDocs(userCollection);
        const userList = userSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setUsers(userList);
    };
    React.useEffect(() => {
        fetchUsers();
    }, []);

    const [role, setRole] = React.useState('user');
    const [name, setName] = React.useState('');
    const [adress, setAdress] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [updateId, setUpdateId] = React.useState('');

    const db = getFirestore();

    const handleSubmit = (event) => {
        event.preventDefault();

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // The user account was created successfully
                const user = userCredential.user;

                // Create a new document in the Firestore database
                
                setDoc(doc(db, "users", user.uid), {
                    name: name,
                    address: adress,
                    phoneNumber: phone,
                    role: role,
                    email: email
                }).then(() => {
                    alert("Kullanıcı oluşturuldu.");
                    setOpen(false);
                    setAdress("");
                    setEmail("");
                    setName("");
                    setPassword("");
                    setPhone("");
                    setRole("user");
                    fetchUsers(); //refresh the table

                }).catch((error) => {
                    console.error("Kullanıcı oluşturulamadı. HATA: ", error);
                });
            })
            .catch((error) => {
                // An error occurred while creating the user account
                alert("Kullanıcı oluşturulamadı. " + error.message.replace("Firebase: ", "")); // Fixed line
            });
    };

    const handleUpdate = (event) => {
        event.preventDefault();
        updateDoc(doc(db, "users", updateId), {
            name: name,
            address: adress,
            phoneNumber: phone,
            role: role,
        }).then(() => {
            alert("Kullanıcı güncellendi.");
            setOpenUpdate(false);
            setAdress("");
            setEmail("");
            setName("");
            setPassword("");
            setPhone("");
            setRole("user");
            fetchUsers(); //refresh the table

        }).catch((error) => {
            console.error("Kullanıcı güncellenemedi. HATA: ", error);
        });
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button sx={{mb:2}} startIcon={<AddIcon />} 
                            variant="contained" size='small' onClick={()=>{handleOpen()}}>
                        Yeni Kullanıcı
                    </Button>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent:'end', alignItems: 'right' }}>
                                <Title>Kullanıcılar</Title>
                                <TextField
                                    label="Kullanıcı Ara"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    style={{ marginLeft: 'auto' }}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              edge="end"
                                              onClick={() => setSearchTerm('')}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                />
                                <FormControl variant="outlined" style={{ marginLeft: '1rem' }}>
                                    <InputLabel id="user-type-label">Kullanıcı Tipi</InputLabel>
                                    <Select
                                        labelId="user-type-label"
                                        id="user-type"
                                        value={userType}
                                        onChange={(event) => setUserType(event.target.value)}
                                        label="Kullanıcı Tipi"
                                        style={{ minWidth: '100px' }}
                                        size="small"
                                    >
                                        <MenuItem value={"Hepsi"}>Hepsi</MenuItem>
                                        <MenuItem value={"user"}>Personel</MenuItem>
                                        <MenuItem value={"muhasebe"}>Muhasebe</MenuItem>
                                    </Select>
                                </FormControl>    
                            </div>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Ad Soyad</b></TableCell>
                                        <TableCell><b>E-Mail</b></TableCell>
                                        <TableCell><b>Tip</b></TableCell>
                                        <TableCell><b>Telefon Numarası</b></TableCell>
                                        <TableCell><b>Adres</b></TableCell>
                                        <TableCell align="right"><b>İşlemler</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {users.filter(user => 
                                            (user.name.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                            user.email.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) &&
                                            (userType === "Hepsi" || user.role === userType)
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => (                                        
                                        <TableRow key={user.id} className="tableRow">
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.role === "user" ? "Personel":"Muhasebe"}</TableCell>
                                            <TableCell>{user.phoneNumber}</TableCell>
                                            <TableCell>{user.address}</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => {handleOpenUpdate(user.id,user.name,user.role,user.phoneNumber,user.address)}} 
                                                            color='primary' size='small'>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                                    
                            </Table>
                            <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={users.filter(user => 
                                            (user.name.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                            user.email.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) &&
                                            (userType === "Hepsi" || user.role === userType)
                                        ).length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(event, newPage) => setPage(newPage)}
                                        labelRowsPerPage="Sayfa başına satır:"
                                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                                    />
                        </React.Fragment>
                    </Paper>
                </Grid>              
            </Grid>
            {/* Create User Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Modal content */}
                    
                    <Divider>
                        <Typography variant='h5'>Yeni Kullanıcı Oluştur</Typography>
                    </Divider>
                    <form onSubmit={handleSubmit} style={{ maxWidth: '600px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Ad Soyad"
                                    variant="outlined"
                                    margin="normal"
                                    id='name'
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Adres"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setAdress(event.target.value)}
                                    value={adress}
                                    id='adress'
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Telefon Numarası"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setPhone(event.target.value)}
                                    value={phone}
                                    id='phone'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setEmail(event.target.value)}
                                    value={email}
                                    id='email'
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Şifre"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setPassword(event.target.value)}
                                    value={password}
                                    id='password'
                                    required
                                    fullWidth
                                />
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <Select
                                        value={role}
                                        onChange={(event) => setRole(event.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        id='role'
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value={"user"}>Personel</MenuItem>
                                        <MenuItem value={"muhasebe"}>Muhasebe</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{ display: 'flex', justifyContent:'flex-end', marginTop: '16px' }}>
                            <Button onClick={handleClose} variant="outlined" color="primary" style={{ margin: '8px' }}>
                                İptal
                            </Button>
                            <Button type="submit" variant="contained" color="primary" style={{ margin: '8px' }}>
                                Oluştur
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
            
            {/* Update User Modal */}
            <Modal
                open={openUpdate}
                onClose={handleCloseUpdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Modal content */}
                    
                    <Divider>
                        <Typography variant='h5'>Kullanıcıyı Düzenle</Typography>
                    </Divider>
                    <form onSubmit={handleUpdate} style={{ maxWidth: '600px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Ad Soyad"
                                    variant="outlined"
                                    margin="normal"
                                    id='name'
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Adres"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setAdress(event.target.value)}
                                    value={adress}
                                    id='adress'
                                    required
                                    fullWidth
                                />
                                
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Telefon Numarası"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setPhone(event.target.value)}
                                    value={phone}
                                    id='phone'
                                    required
                                    fullWidth
                                />
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <Select
                                        value={role}
                                        onChange={(event) => setRole(event.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        id='role'
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value={"user"}>Personel</MenuItem>
                                        <MenuItem value={"muhasebe"}>Muhasebe</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{ display: 'flex', justifyContent:'flex-end', marginTop: '16px' }}>
                            <Button onClick={handleCloseUpdate} variant="outlined" color="primary" style={{ margin: '8px' }}>
                                İptal
                            </Button>
                            <Button type="submit" variant="contained" color="primary" style={{ margin: '8px' }}>
                                Güncelle
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </Container>
        
    );
}
