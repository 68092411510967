import React from 'react';
import { useEffect,useState } from 'react';
import { HashRouter as Router,Route, Routes, Navigate} from 'react-router-dom';
import { LogInPage } from './components/auth'; // Adjust the path based on your project structure
import Dashboard from './dashboard/Dashboard'; // Adjust the path based on your project structure
import {auth} from "./config/firebase"
import AdminDashboard from "./adminPage/AdminDashboard"

const App = () => {

  const [authInitialized, setAuthInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      
      setAuthInitialized(true);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(()=>{
    if(user && (user.email === "admin@admin.com" || user.email === "ik@gmail.com" ))
      setIsAdmin(true);
  },[user])

  if (!authInitialized) {
    // Return a loading indicator or placeholder while authentication is being initialized
    return <div>Yükleniyor...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <LogInPage/>} />

      {/* Redirect to login page if not authenticated */}
      <Route
        path="/"
        element={
          user && isAdmin ? (
            <Navigate to="/admin" />
          ) : user && !isAdmin ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/login" />
          )
        }              
      />
      
      <Route
        path="/admin"
        element={
          user && isAdmin ? (
            <AdminDashboard/>
          ) : user && !isAdmin ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      
      <Route
        path="/dashboard"
        element={
          user && isAdmin ? (
            <Navigate to="/admin" />
          ) : user && !isAdmin ? (
            <Dashboard />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    
    </Routes>
  );
};

export default App;
