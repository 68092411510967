import * as React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import { collection, getDocs, doc, setDoc, updateDoc,deleteDoc  } from "firebase/firestore";
import { getFirestore,query,where,orderBy,limit } from "firebase/firestore";
import { getCityNameByPlate } from '../components/plateToCity'

export default function UpcomingRidesSmall( {handleChangeComponent} ) {
  const [rides, setRides] = React.useState("summary");

  const db = getFirestore();

  React.useEffect(() => {
    const fetchRides = async () => {
      const ridesCollection = collection(db, 'rides');
      const q = query(ridesCollection, where("status", "==", "upcoming"), orderBy("activeTime"), limit(5));
      const ridesSnapshot = await getDocs(q);
      const rideList = ridesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setRides(rideList);
    };
    fetchRides();
  }, []);

  return (
    <React.Fragment>
      <Title>Yaklaşan Yolculuklar</Title>
      <Table size="small">
          <TableHead>
              <TableRow>
                  <TableCell><b>Yolculuk Adı</b></TableCell>
                  <TableCell><b>Personel</b></TableCell>
                  <TableCell><b>Başlangıç Zamanı</b></TableCell>
                  <TableCell><b>Oluşturan</b></TableCell>
                  <TableCell><b>Şehir</b></TableCell>
                  <TableCell><b>Adres</b></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(rides) && rides.map((ride) => (                                        
                  <TableRow key={ride.id}>
                      <TableCell>{ride.title}</TableCell>
                      <TableCell>{ride.userId}</TableCell>
                      <TableCell>{ride.activeTime.toDate().toLocaleString('en-GB')}</TableCell>
                      <TableCell>{ride.createdBy}</TableCell>
                      <TableCell>{getCityNameByPlate(ride.city)}</TableCell>
                      <TableCell>{ride.address}</TableCell>
                  </TableRow>
              ))}
          </TableBody>
              
      </Table>
      <Button color="primary" onClick={() => handleChangeComponent("upcomingRides")} sx={{ mt: 3 }}>
        Tümünü Gör
      </Button>
    </React.Fragment>
  );
}
