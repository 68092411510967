import * as React from 'react';
import { collection, getDocs  } from "firebase/firestore";
import { getFirestore,query,where,orderBy } from "firebase/firestore";
import TablePagination from '@mui/material/TablePagination';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import "./tableStyle.css";
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import { getCityNameByPlate } from '../components/plateToCity'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const convertMetersToKilometers= (inputDistance) => {
    if(inputDistance === undefined) return ("");
    if(inputDistance === null) return ("");
    if(inputDistance === "") return ("");
    if(inputDistance <= 0) return (inputDistance);
    const distanceInKilometers = Math.floor(inputDistance / 1000);
    const remainingMeters = inputDistance % 1000;

    const distanceString = `${distanceInKilometers}km ${remainingMeters}m`;
    return distanceString;
  }


  const convertSecondsToMinutesAndSeconds = (inputTime) => {
    if(inputTime === undefined) return ("");
    if(inputTime === null) return ("");
    if(inputTime === "") return ("");
    if(inputTime <= 0) return (inputTime);

    const minutes = Math.floor(inputTime / 60);
    const seconds = inputTime % 60;
  
    const minutesString = minutes > 0 ? `${minutes} dakika` : '';
    const secondsString = seconds > 0 ? `${seconds} saniye` : '';

    //console.log("zaman: "+[minutesString, secondsString].filter(Boolean).join(' '));
    return [minutesString, secondsString].filter(Boolean).join(' ');
  };

export default function PrevRidesPage() {
    const db = getFirestore();
    const [isLoading, setIsLoading] = React.useState(false);
    const [details, setDetails] = React.useState("");

    const fetchDetails = async (rideId) => {
      const resultsCollection = collection(db, 'results');
      const q = query(resultsCollection, where("belgeId", "==", rideId));
      const resultsSnapshot = await getDocs(q);
      const resultsList = resultsSnapshot.docs.map(doc => doc.data());
      return resultsList[0];
    };

    const [openDetails, setOpenDetails] = React.useState(false);

    const handleOpenDetails = (rideId) => {
      setIsLoading(true); // start loading
      setOpenDetails(true);
      fetchDetails(rideId).then((result) => {
          console.log(result);
          setDetails(result);
          setIsLoading(false); // stop loading
      });
    };

    const handleCloseDetails = () => {
        setOpenDetails(false);
    }

    const [checked1, setChecked1] = React.useState(true);
    const [checked2, setChecked2] = React.useState(true);

    const handleCheck1 = (event) => {
      //console.log("1"+event.target.checked);
      setChecked1(event.target.checked);
    };
    const handleCheck2 = (event) => {
      //console.log("2"+event.target.checked);
      setChecked2(event.target.checked);
    };

    const [searchTerm, setSearchTerm] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [cityName, setCityName] = React.useState("");
    const [cityOptions, setCityOptions] = React.useState([]);
    const [rideInfo, setRideInfo] = React.useState([]);
      
    React.useEffect(() => {
        setPage(0);
      }, [searchTerm, cityName,checked1,checked2]);

    const [rides, setRides] = React.useState([]);

    React.useEffect(() => {
      const fetchRides = async () => {
        const ridesCollection = collection(db, 'rides'); 
  
        const q = query(ridesCollection, where("status", "!=", "upcoming"), orderBy("status"), orderBy("activeTime","desc"));      const ridesSnapshot = await getDocs(q);
        const rideList = ridesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setRides(rideList);
      };
      fetchRides();
    },[]);
    

    React.useEffect(() => {
      const fetchCities = async () => {
        const citiesCollection = collection(db, 'cities');
        const citySnapshot = await getDocs(citiesCollection);
        const cityList = citySnapshot.docs.map(doc => {
          const data = doc.data();
          return { label: `[${data.plateNo}] ${data.cityName}`, plateNo: data.plateNo };
        }).sort((a, b) => {
          if ([34, 6, 35].includes(a.plateNo)) return -1;
          if ([34, 6, 35].includes(b.plateNo)) return 1;
          return a.plateNo - b.plateNo;
        }).map(city => city.label);
        setCityOptions(cityList);
      };
      fetchCities();
    },[])

    const [users, setUsers] = React.useState([]);
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const userList = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(userList);
    };
    React.useEffect(() => {
      fetchUsers();
    }, []);

    const getPlateNo = () => {
      const matchNumber = cityName.match(/\[(\d+)\]/);
      var extractedNumber;
      // Check if there is a match
      if (matchNumber && matchNumber[1]) {
        // Convert the matched string to an integer using parseInt
        extractedNumber = parseInt(matchNumber[1], 10);
        return extractedNumber
      }
    }
    
    const getUserName = (email) => {
      if(!users) return null;
      const user = users.find(user => user.email === email);
      return user ? user.name + " (" + email + ")" : null;
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent:'end', alignItems: 'right' }}>
                                <Title>Geçmiş Yolculuklar</Title>
                                <TextField
                                    label="Yolculuk Ara"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    style={{ marginLeft: 'auto' }}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              edge="end"
                                              onClick={() => setSearchTerm('')}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                />
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  onChange={(e,newValue) => {setCityName(newValue)}}
                                  value={cityName}
                                  options={cityOptions}
                                  sx={{ width: 175 ,ml:1 }}
                                  renderInput={(params) => <TextField {...params} label="Şehir" />}
                                /> 
                                <Box display="flex" justifyContent="space-between" width={100} ml={1} mr={4}>
                                  <FormControlLabel
                                    control={<Checkbox checked={checked1} onChange={handleCheck1} color='success'/>}
                                    label="Onay"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={checked2} onChange={handleCheck2} color='error'/>}
                                    label="Ret"
                                  />
                                </Box>
                            </div>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Yolculuk Adı</b></TableCell>
                                        <TableCell><b>Personel</b></TableCell>
                                        <TableCell><b>Başlangıç Zamanı</b></TableCell>
                                        <TableCell><b>Oluşturan</b></TableCell>
                                        <TableCell><b>Şehir</b></TableCell>
                                        <TableCell><b>Durumu</b></TableCell>
                                        <TableCell align="right"><b>Detaylar</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rides.filter(ride => 
                                            (ride.title.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
                                            ride.address.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                            getUserName(ride.userId).toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) 
                                            && (cityName === "" || cityName === null || ride.city === getPlateNo())
                                            && ((checked1 && ride.status === "confirmed") || (checked2 && ride.status === "denied"))
                                            
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((ride) => (                                        
                                        <TableRow key={ride.id} className="tableRow">
                                            <TableCell>{ride.title}</TableCell>
                                            <TableCell>{getUserName(ride.userId)}</TableCell>
                                            <TableCell>{ride.activeTime.toDate().toLocaleString('en-GB')}</TableCell>
                                            <TableCell>{ride.createdBy}</TableCell>
                                            <TableCell>{getCityNameByPlate(ride.city)}</TableCell>
                                            <TableCell>{
                                              ride.status ==="confirmed" ? 
                                              <Chip label="Onaylandı" color="success" onClick={() => {
                                                setRideInfo([ride.title, ride.userId, ride.activeTime.toDate().toLocaleString('en-GB'), ride.status]);
                                                handleOpenDetails(ride.id);
                                              }}/> 
                                              : <Chip label="Reddedildi" color="error" onClick={() => {
                                                setRideInfo([ride.title, ride.userId, ride.activeTime.toDate().toLocaleString('en-GB'), ride.status]);
                                                handleOpenDetails(ride.id);
                                              }}/>
                                            }</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => {
                                                  setRideInfo([ride.title, ride.userId, ride.activeTime.toDate().toLocaleString('en-GB'), ride.status]);
                                                  handleOpenDetails(ride.id);
                                                }}
                                                            color='primary' size='small'>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                                    
                            </Table>
                            <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={rides.filter(ride => 
                                          (ride.title.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
                                          ride.address.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                          ride.userId.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) 
                                          && (cityName === "" || cityName === null || ride.city === getPlateNo())
                                          && ((checked1 && ride.status === "confirmed") || (checked2 && ride.status === "denied"))
                                          
                                      ).length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(event, newPage) => setPage(newPage)}
                                        labelRowsPerPage="Sayfa başına satır:"
                                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                                    />
                        </React.Fragment>
                    </Paper>
                </Grid>              
            </Grid>
            
            {/* Ride Details Modal */}
            <Modal
                open={openDetails}
                onClose={handleCloseDetails}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Modal content */}
                    {/*TODO: IMPLEMENT */}
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton onClick={handleCloseDetails}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    
                    {/*TODO: Add ride details (photo,prices,time etc.) */}  
                    {isLoading ? <div>Yükleniyor...</div> :
                      details ? (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Paper>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                
                                <h2 id="modal-modal-title">Yolculuk Detayları</h2>
                                <p id="modal-modal-description">
                                    <b>Yolculuk Adı:</b> {rideInfo[0]} <br/>
                                    <b>Personel:</b> {rideInfo[1]} <br/>
                                    <b>Başlangıç Zamanı:</b> {rideInfo[2]} <br/>
                                    <b>Bitiş Zamanı:</b> {details.time ? details.time.toDate().toLocaleString('en-GB') : null} <br/>
                                    <b>Adres:</b> {details.address} <br/>
                                    {/*TODO: change from m and sn to km and saat */}
                                    <b>Gidilen Uzaklık:</b> {convertMetersToKilometers(details.distanceValue)} <br/>
                                    <b>Yolculuk Süresi:</b> {convertSecondsToMinutesAndSeconds(details.durationValue)}<br/>
                                    <b>Hesaplanan Ücret:</b> {details.estimatedPrice}₺ <br/>
                                    <b>Girilen Ücret:</b> {details.resultPrice}₺ <br/>
                                    <b>Durum:</b> {rideInfo[3] ==="confirmed" ? <Chip label="Onaylandı" color="success" /> 
                                                  : <Chip label="Reddedildi" color="error" />} <br/><br/> </p>
                             </Box>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper>
                              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <h2 id="modal-modal-title">Yolculuk Faturası</h2>
                                <a href={details.photoUrl} target="_blank" rel="noopener noreferrer">
                                  <img src={details.photoUrl} alt="Yüklenen Fatura" style={{ maxWidth: '100%', maxHeight: '500px' }}/>
                                </a>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>): null}          
                </Box>
            </Modal>
        </Container>
        
    );
}
