// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDjHNfM8fS8jJ7GthCy_fe_7K0qw-S21-o",
  authDomain: "ytu-travel-app.firebaseapp.com",
  projectId: "ytu-travel-app",
  storageBucket: "ytu-travel-app.appspot.com",
  messagingSenderId: "773307451778",
  appId: "1:773307451778:web:7126b8435a4fcb2723e79b",
  measurementId: "G-WYJKWPFKPC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getFirestore(app);