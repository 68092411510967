import * as React from 'react';
import { collection, getDocs, doc, updateDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import "./tableStyle.css";
import TablePagination from '@mui/material/TablePagination';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./tableStyle.css";
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function FaresPage() {

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = (id,cityName,plateNo,minPrice,perKmFare,startingFare) => {
    setUpdateId(id);
    setCityName(cityName);
    setPlateNo(plateNo);
    setMinPrice(minPrice);
    setPerKmFare(perKmFare);
    setStartingFare(startingFare);
    setOpenUpdate(true);
  }
  const handleCloseUpdate = () => {
    setUpdateId("");
    setCityName("");
    setPlateNo(0);
    setMinPrice(0);
    setPerKmFare(0);
    setStartingFare(0);
    setOpenUpdate(false);
  }
  
  const [searchTerm, setSearchTerm] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
      setPage(0);
    }, [searchTerm]);

  const [cities, setCities] = React.useState([]);
  const fetchCities = async () => {
      const db = getFirestore();
      const userCollection = collection(db, "cities");
      const userSnapshot = await getDocs(userCollection);
      const userList = userSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      
      // Sort the userList array ascending according to the plateNo property
      userList.sort((a, b) => {
        // Convert plateNo to number
        const plateNoA = Number(a.plateNo);
        const plateNoB = Number(b.plateNo);
      
        // Prioritize 34, 35, and 06
        const priorityNumbers = [34, 6, 35];
        const isAPriority = priorityNumbers.includes(plateNoA);
        const isBPriority = priorityNumbers.includes(plateNoB);
      
        if (isAPriority && !isBPriority) {
          return -1; // a comes first
        } else if (!isAPriority && isBPriority) {
          return 1; // b comes first
        } else if (isAPriority && isBPriority) {
          return priorityNumbers.indexOf(plateNoA) - priorityNumbers.indexOf(plateNoB); // sort by priority order
        } else {
          return plateNoA - plateNoB; // sort by plateNo
        }
      });

      setCities(userList);
  };
  React.useEffect(() => {
      fetchCities();
  }, []);

  const [cityName, setCityName] = React.useState('');
  const [plateNo, setPlateNo] = React.useState(0);
  const [startingFare, setStartingFare] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [perKmFare, setPerKmFare] = React.useState(0);
  const [updateId, setUpdateId] = React.useState(0);

  const db = getFirestore();

  const handleUpdate = (event) => {
      event.preventDefault();
      updateDoc(doc(db, "cities", updateId), {
          minPrice: Number(minPrice),
          startingFare: Number(startingFare),
          perKmFare: Number(perKmFare),
      }).then(() => {
          alert("Taksi Ücreti Güncellendi.");
          handleCloseUpdate();
          fetchCities(); //refresh the table

      }).catch((error) => {
          console.error("Taksi Ücreti Güncellenemedi. HATA: ", error);
      });
  }

  return (
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
            
              {/* Previous Rides */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <React.Fragment>
                  <div style={{ display: 'flex', justifyContent:'end', alignItems: 'right' }}>
                    <Title>Taksi Ücretleri</Title>
                    <TextField
                                    label="Şehir Ara"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    style={{ marginLeft: 'auto' }}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              edge="end"
                                              onClick={() => setSearchTerm('')}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                />
                              </div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>Şehir</b></TableCell>
                                    <TableCell><b>Açılış Ücreti</b></TableCell>
                                    <TableCell><b>KM Başına Ücret</b></TableCell>
                                    <TableCell><b>Minimum Ücret (İndi-Bindi)</b></TableCell>
                                    <TableCell align="right"><b>İşlemler</b></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {cities.filter(city => 
                                            (city.cityName.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                            city.plateNo===Number((searchTerm.toLowerCase())))
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((city) => (
                                <TableRow key={city.id} className="tableRow">
                                <TableCell>{city.plateNo}</TableCell>
                                <TableCell align="left">{city.cityName}</TableCell>
                                <TableCell>{city.startingFare}</TableCell>
                                <TableCell>{city.perKmFare}</TableCell>
                                <TableCell>{city.minPrice}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => {handleOpenUpdate(city.id,city.cityName,city.plateNo,city.minPrice,city.perKmFare,city.startingFare)}}
                                                color='primary' size='small'>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            
                        </Table>
                        <TablePagination
                                rowsPerPageOptions={[5,10, 20, 50]}
                                component="div"
                                count={cities.filter(city => 
                                  (city.cityName.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                  city.plateNo===Number((searchTerm.toLowerCase())))
                                  ).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => setPage(newPage)}
                                labelRowsPerPage="Sayfa başına satır:"
                                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                            />
                </React.Fragment>
                </Paper>
              </Grid>
            </Grid>
                        
            {/* Update City Modal */}
            <Modal
                open={openUpdate}
                onClose={handleCloseUpdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Modal content */}
                    
                    <Divider>
                        <Typography variant='h5'>Taksi Ücreti Düzenle</Typography>
                    </Divider>
                    <form onSubmit={handleUpdate} style={{ maxWidth: '300px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Şehir"
                                    variant="outlined"
                                    margin="normal"
                                    id='name'
                                    value={cityName + " (" + plateNo + ")"}
                                    disabled
                                    fullWidth
                                />
                                
                                
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Açılış Ücreti"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setStartingFare(event.target.value)}
                                    value={startingFare}
                                    id='phone'
                                    required
                                    fullWidth
                                    type="number"
                                    
                                />
                                <TextField
                                    label="KM Başına Ücret"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setPerKmFare(event.target.value)}
                                    value={perKmFare}
                                    id='phone'
                                    required
                                    fullWidth
                                    type="number" 
                                />
                                <TextField
                                    label="Minimum (İndi-Bindi) Ücret"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={(event) => setMinPrice(event.target.value)}
                                    value={minPrice}
                                    id='adress'
                                    required
                                    fullWidth
                                    type="number"
                                    
                                />
                            </Grid>
                        </Grid>
                        <div style={{ display: 'flex', justifyContent:'flex-end', marginTop: '16px' }}>
                            <Button onClick={handleCloseUpdate} variant="outlined" color="primary" style={{ margin: '8px' }}>
                                İptal
                            </Button>
                            <Button type="submit" variant="contained" color="primary" style={{ margin: '8px' }}>
                                Güncelle
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
          </Container>
  );
}
