import * as React from 'react';
import "./AddRidePage.css";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getAuth } from "firebase/auth";
import { updateDoc,addDoc, collection, getDocs, query,where ,doc,getDoc   } from "firebase/firestore";
import { getFirestore , Timestamp } from "firebase/firestore";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MapContainer, TileLayer, useMap,Marker, Popup ,useMapEvents } from 'react-leaflet'
import axios from "axios";


const steps = ['Yolculuk Bilgileri', 'Adres', 'Gözden Geçir'];

export default function AddRidePage(props) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [rideTitle, setRideTitle] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [activeTime, setActiveTime] = React.useState(dayjs(dayjs().format('YYYY-MM-DD HH:mm')));
  const [cityName, setCityName] = React.useState("");
  const [adress, setAdress] = React.useState("");
  const [updateId, setUpdateId] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("YOLCULUK OLUŞTUR");
  const [successMessage, setSuccessMessage] = React.useState("oluşturuldu");
  const [createMessage, setCreateMessage] = React.useState("Oluştur");
  
  const db = getFirestore();

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  const [cityOptions, setCityOptions] = React.useState([]);
  const [mapHeight, setMapHeight] = React.useState("500px");

  const fetchRide = async (id) => {
    const docRef = doc(db, "rides", id);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  };
  
  React.useEffect(() => {

    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserId(user.email);
    } else {
      console.log("Kullanıcı bulunamadı");
    }

    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userQuery = query(usersCollection, where("role", "==", "user"));
      const userSnapshot = await getDocs(userQuery);
      const userList = userSnapshot.docs.map(doc => {
        const data = doc.data();
        return `${data.name} (${data.email})`; // replace 'name' and 'email' with your actual field names
      });
      setOptions(userList);
    };
    const fetchCities = async () => {
      const citiesCollection = collection(db, 'cities');
      const citySnapshot = await getDocs(citiesCollection);
      const cityList = citySnapshot.docs.map(doc => {
        const data = doc.data();
        return { label: `[${data.plateNo}] ${data.cityName}`, plateNo: data.plateNo };
      }).sort((a, b) => {
        if ([34, 6, 35].includes(a.plateNo)) return -1;
        if ([34, 6, 35].includes(b.plateNo)) return 1;
        return a.plateNo - b.plateNo;
      }).map(city => city.label);
      setCityOptions(cityList);
    };
    fetchUsers();
    fetchCities();

  },[]);

  React.useEffect(() => {
    const fetchAndSetRide = async () => {
      if (props.updateId !== undefined && props.city !== undefined && props.user !== undefined) {
        const ride = await fetchRide(props.updateId);
        if (ride) {
          setUpdateId(props.updateId);
          setPageTitle("YOLCULUK GÜNCELLE");
          setCreateMessage("Güncelle");
          setSuccessMessage("güncellendi");
          setMapHeight("300px");
          setRideTitle(ride.title);
          setActiveTime(dayjs(ride.activeTime.toDate()));
          setSelectedUser(props.user);
          setCitySelectorSize(200);
          setCityName(props.city);
          setAdress(ride.address);
          setClickedPosition(ride.destination);
        }
      }
    };
  
    fetchAndSetRide();
  }, [props.updateId]);

  const filterOptions = createFilterOptions({
    limit: 5
  });

  const [clickedPosition, setClickedPosition] = React.useState(null);
  const [citySelectorSize, setCitySelectorSize] = React.useState(500);
  
  const handleMapClick = async () => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?key=cc57d5173fb8421083fcf3a5b83df6c0&q=${clickedPosition[0]}+${clickedPosition[1]}&pretty=1&language=tr`
      );

      const { results } = response.data;

      if (results.length > 0) {
        const { formatted } = results[0];
        setAdress(formatted);
      } else {
        setAdress('Address bulunmadı');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setAdress('Error fetching address');
    }
  };
  function AddMarkerOnClick() {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setClickedPosition([lat, lng]);
        console.log("Clicked: "+lat + " " + lng);
      }
    });
    return clickedPosition === null ? null : (
        <Marker position={clickedPosition}>
          <Popup>
            Coordinates: <br />
            Lat: {clickedPosition[0]} <br />
            Lng: {clickedPosition[1]}
          </Popup>
        </Marker>
    );
  }
  React.useEffect(() => {
    if (clickedPosition) {
      handleMapClick();
    }
  }, [clickedPosition]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async () => {
    const matchNumber = cityName.match(/\[(\d+)\]/);
    const matchEmail = selectedUser.match(/\(([^)]+)\)/);
    var extractedEmail;
    var extractedNumber;
    // Check if there is a match
    if (matchNumber && matchNumber[1]) {
      // Convert the matched string to an integer using parseInt
      extractedNumber = parseInt(matchNumber[1], 10);
    }
    if (matchEmail && matchEmail[1]) {
      extractedEmail = matchEmail[1];
    }
    if (props.updateId === undefined) {
      const docRef = await addDoc(collection(db, "rides"), {
        title: rideTitle,
        activeTime: Timestamp.fromDate(new Date(activeTime)),
        createdBy: userId,
        city: extractedNumber,
        address: adress,
        destination: clickedPosition,
        status: "upcoming",
        userId: extractedEmail
      });
    } else {
      const docRef = doc(db, "rides", updateId);
      await updateDoc(docRef,{
        title: rideTitle,
        activeTime: Timestamp.fromDate(new Date(activeTime)),
        createdBy: userId,
        city: extractedNumber,
        address: adress,
        destination: clickedPosition,
        status: "upcoming",
        userId: extractedEmail
      });
    }
  }

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41], 
    iconAnchor: [12,41]
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              {pageTitle}
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (//success component
              <React.Fragment>
                <Typography variant="h4" gutterBottom>
                  <b>Yolculuk {successMessage}</b>
                </Typography>
                <Typography variant="subtitle1">
                  <b>{rideTitle}</b> isimli yolculuk <b>{selectedUser}</b> için {successMessage}.<br/> 
                  Yolculuk <b>{activeTime.format('YYYY-MM-DD HH:mm:ss')}</b> tarihinde başlayacak 
                  ve <b>{cityName}</b> şehrinde <b>{adress}</b> adresine gidilecek.
                </Typography>
                <Button 
                  variant="contained" 
                  sx={{ mt: 3 }}
                  onClick={props.close !== undefined ? props.close : () => window.location.reload()}
                >
                  Kapat
                </Button>
              </React.Fragment>
            ) : ( //info component
              activeStep === 0 ? (
                <React.Fragment>
                  <Typography variant="h6" gutterBottom >
                    <b>Yolculuk Bilgileri</b>
                  </Typography>
                  
                  <Grid container spacing={2} >
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="rideTitle"
                        name="rideTitle"
                        label="Yolculuk İsmi"
                        onChange={(e) => setRideTitle(e.target.value)}
                        value={rideTitle}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          id="activeTime"
                          name="activeTime"
                          label="Başlangıç Zamanı"
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          value={activeTime}
                          onChange={(newValue) => {
                            setActiveTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        value={selectedUser}
                        onChange={(event, newValue) => {
                          setSelectedUser(newValue);
                        }}
                        options={options}
                        renderInput={(params) => <TextField {...params} label="Personel" />}
                        noOptionsText="Kullanıcı Bulunamadı"
                        filterOptions={filterOptions}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        <ArrowBackIcon/>
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={
                        (rideTitle !== "" && activeTime !=="" && selectedUser !== "") ? 
                        handleNext 
                        : () => alert("Lütfen tüm alanları doldurunuz.")
                      }
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? 'Oluştur' : <ArrowForwardIcon/>}
                    </Button>
                  </Box>
                </React.Fragment>) 
              : activeStep === 1 ? (//address component
                <React.Fragment>
                  <Typography variant="h6" gutterBottom>
                    <b>Adres Bilgileri</b>
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e,newValue) => {
                          setCityName(newValue); 
                          setCitySelectorSize(200)
                        }}
                        value={cityName}
                        options={cityOptions}
                        sx={{ width: citySelectorSize ,mt: 1 }}
                        renderInput={(params) => <TextField {...params} label="Şehir" />}
                      />
                    </Grid>
                    {cityName !== "" && (
                    <Grid item xs={12} sm={7}>
                      <TextField
                        disabled
                        id="adress"
                        label="Adres Bilgileri"
                        onChange={(e) => setAdress(e.target.value)}
                        value={adress}
                        multiline
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                      />
                    </Grid>)}
                    <Grid item xs={12}>
                    {cityName !== "" && (
                      <div style={{ height: mapHeight }}>
                        <Typography variant="h7" gutterBottom>Gidilecek Adresi Haritadan Seçiniz</Typography>
                        <MapContainer 
                          style={{ height: "100%", minHeight: "100%" }} 
                          center={[41.015, 28.98]} 
                          zoom={11} 
                          scrollWheelZoom={true}
                          onClick={(e) => {
                            setClickedPosition(e.latlng);
                            console.log("Clicked: "+e.latlng.lat + " " + e.latlng.lng);
                          }}
                        >
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <AddMarkerOnClick/>
                        </MapContainer>
                      </div>)}
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        <ArrowBackIcon/>
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={
                        (cityName !== "" && cityName !== null && adress !=="" ) ? 
                        handleNext 
                        : () => alert("Lütfen tüm alanları doldurunuz.")
                      }
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? 'Oluştur' : <ArrowForwardIcon/>}
                    </Button>
                  </Box>
                </React.Fragment>) 
              : ( //review component
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    <b>Yolculuk Özeti</b>
                  </Typography>
                  <List disablePadding>
                    <ListItem sx={{ py: 1, px: 0 }}>
                        <ListItemText primary="Yolculuk İsmi"/>
                        <Typography variant="body2" sx={{ maxWidth:300 }} align='right'>
                          <i>{rideTitle}</i>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                        <ListItemText primary="Çalışan" />
                        <Typography variant="body2"><i>{selectedUser}</i></Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                        <ListItemText primary="Başlangıç Zamanı" secondary="Yolculuğa ne zamandan itibaren başlanabileceği" />
                        <Typography variant="body2"><i>{activeTime.format('YYYY-MM-DD HH:mm:ss')}</i></Typography>
                    </ListItem>
                    <ListItem sx={{ py: 1, px: 0 }}>
                        <ListItemText primary="Adres" secondary="Kordinatlar" />
                        <Typography variant="body2" sx={{ maxWidth:300 }} align='right'>
                          <i>{adress}</i>
                          <Typography variant="body2" component="span" sx={{ fontSize: '0.8em', display: 'block' }}>
                            {clickedPosition[0] + " , " + clickedPosition[1]}
                          </Typography>
                        </Typography>
                    </ListItem>
                    

                  </List>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        <ArrowBackIcon/>
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={()=>{
                        handleSubmit();
                        handleNext();
                      }}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep !== steps.length - 1 ? <ArrowForwardIcon/> : createMessage }
                    </Button>
                  </Box>
                </React.Fragment>)
            )}
          </Paper>
        </Container>
);
}
