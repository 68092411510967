import * as React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import { collection, getDocs  } from "firebase/firestore";
import { getFirestore,query,where,orderBy,limit } from "firebase/firestore";
import Chip from '@mui/material/Chip';
import { getCityNameByPlate } from '../components/plateToCity'


export default function PrevRidesSmall( {handleChangeComponent} ) {
  const [rides, setRides] = React.useState("summary");

  const db = getFirestore();

  React.useEffect(() => {
    const fetchRides = async () => {
      const ridesCollection = collection(db, 'rides');
      const q = query(ridesCollection, where("status", "!=", "upcoming"), orderBy("status"), orderBy("activeTime","desc"), limit(5));
      const ridesSnapshot = await getDocs(q);
      const rideList = ridesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setRides(rideList);
    };
    fetchRides();
  },[]);

  return (
    <React.Fragment>
      <Title>Biten Yolculuklar</Title>
      <Table size="small">
          <TableHead>
              <TableRow>
                <TableCell><b>Yolculuk Adı</b></TableCell>
                <TableCell><b>Personel</b></TableCell>
                <TableCell><b>Başlangıç Zamanı</b></TableCell>
                <TableCell><b>Oluşturan</b></TableCell>
                <TableCell><b>Şehir</b></TableCell>
                <TableCell><b>Durumu</b></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(rides) && rides.map((ride) => (                                        
                  <TableRow key={ride.id}>
                    <TableCell>{ride.title}</TableCell>
                    <TableCell>{ride.userId}</TableCell>
                    <TableCell>{ride.activeTime.toDate().toLocaleString('en-GB')}</TableCell>
                    <TableCell>{ride.createdBy}</TableCell>
                    <TableCell>{getCityNameByPlate(ride.city)}</TableCell>
                    <TableCell>{
                      ride.status ==="confirmed" ? 
                      <Chip label="Onaylandı" color="success" /> 
                      : <Chip label="Reddedildi" color="error" />
                    }</TableCell> 
                  </TableRow>
              ))}
          </TableBody>
              
      </Table>
      <Button color="primary" onClick={() => handleChangeComponent("previousRides")} sx={{ mt: 3 }}>
        Tümünü Gör
      </Button>
    </React.Fragment>
  );
}
