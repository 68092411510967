import * as React from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, doc, setDoc, updateDoc,deleteDoc  } from "firebase/firestore";
import { getFirestore,query,where,orderBy } from "firebase/firestore";
import AddRidePage from '../createRide/AddRidePage';
import TablePagination from '@mui/material/TablePagination';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import "./tableStyle.css";
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { getCityNameByPlate } from '../components/plateToCity'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export default function UpcomingRidesPage() {
    const [openUpdate, setOpenUpdate] = React.useState(false);

    const [cityUpdate, setCityUpdate] = React.useState("error");
    const [userUpdate, setUserUpdate] = React.useState("error");

    const fetchUser = async (email) => {
      const usersCollection = collection(db, 'users');
      const userQuery = query(usersCollection, where("email", "==", email));
      const userSnapshot = await getDocs(userQuery);
      const userList = userSnapshot.docs.map(doc => {
        const data = doc.data();
        return `${data.name} (${data.email})`; // replace 'name' and 'email' with your actual field names
      });
      return userList[0];
    };

    const handleOpenUpdate = (id,city,user) => {
        setUpdateId(id);
        city = cityOptions.find(cityOption => cityOption.includes(`[${city}]`));
        fetchUser(user).then((fetchedUser) => {
          setCityUpdate(city);
          setUserUpdate(fetchedUser);
          setOpenUpdate(true);
        });
    };

    const handleCloseUpdate = () => {
        setUpdateId(undefined);
        fetchRides();
        setOpenUpdate(false);
    }

    
    const [searchTerm, setSearchTerm] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [cityName, setCityName] = React.useState("");
    const [cityOptions, setCityOptions] = React.useState([]);
      
    const db = getFirestore();

    React.useEffect(() => {
        setPage(0);
      }, [searchTerm, cityName]);

    const [rides, setRides] = React.useState([]);
    const fetchRides = async () => {
      const ridesCollection = collection(db, 'rides');
      const q = query(ridesCollection, where("status", "==", "upcoming"), orderBy("activeTime"));
      const ridesSnapshot = await getDocs(q);
      const rideList = ridesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setRides(rideList);
    };
    React.useEffect(() => {
      fetchRides();
    }, []);

    const [users, setUsers] = React.useState([]);
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const userList = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(userList);
    };
    React.useEffect(() => {
      fetchUsers();
    }, []);
    
    const [updateId, setUpdateId] = React.useState('');

    React.useEffect(() => {
      const fetchCities = async () => {
        const citiesCollection = collection(db, 'cities');
        const citySnapshot = await getDocs(citiesCollection);
        const cityList = citySnapshot.docs.map(doc => {
          const data = doc.data();
          return { label: `[${data.plateNo}] ${data.cityName}`, plateNo: data.plateNo };
        }).sort((a, b) => {
          if ([34, 6, 35].includes(a.plateNo)) return -1;
          if ([34, 6, 35].includes(b.plateNo)) return 1;
          return a.plateNo - b.plateNo;
        }).map(city => city.label);
        setCityOptions(cityList);
      };
      fetchCities();
    }, []);

    const getPlateNo = () => {
      const matchNumber = cityName.match(/\[(\d+)\]/);
      var extractedNumber;
      // Check if there is a match
      if (matchNumber && matchNumber[1]) {
        // Convert the matched string to an integer using parseInt
        extractedNumber = parseInt(matchNumber[1], 10);
        return extractedNumber
      }
    }

    const getUserName = (email) => {
      if(!users) return null;
      const user = users.find(user => user.email === email);
      return user ? user.name + " (" + email + ")" : null;
    }

    const handleDelete = (id) => {
        if (window.confirm("Yolculuğu silmek istediğinize emin misiniz?")) {
            // Delete the document
            deleteDoc(doc(db, "rides", id)).then(() => {
                alert("Yolculuk silindi.");
                fetchRides(); //refresh the table
            }).catch((error) => {
                console.error("Yolculuk silinemedi. HATA: ", error);
            });
        }
    }
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent:'end', alignItems: 'right' }}>
                                <Title>Gelecek Yolculuklar</Title>
                                <TextField
                                    label="Yolculuk Ara"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    style={{ marginLeft: 'auto' }}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              edge="end"
                                              onClick={() => setSearchTerm('')}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                />
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  onChange={(e,newValue) => {setCityName(newValue)}}
                                  value={cityName}
                                  options={cityOptions}
                                  sx={{ width: 200 ,ml:1 }}
                                  renderInput={(params) => <TextField {...params} label="Şehir" />}
                                />
                            </div>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Yolculuk Adı</b></TableCell>
                                        <TableCell><b>Personel</b></TableCell>
                                        <TableCell><b>Başlangıç Zamanı</b></TableCell>
                                        <TableCell><b>Oluşturan</b></TableCell>
                                        <TableCell><b>Şehir</b></TableCell>
                                        <TableCell><b>Adres</b></TableCell>
                                        <TableCell align="right"><b>İşlemler</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rides.filter(ride => 
                                            (ride.title.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
                                            ride.address.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                            getUserName(ride.userId).toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) 
                                            && (cityName === "" || cityName === null || ride.city === getPlateNo())
                                            
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((ride) => (                                        
                                        <TableRow key={ride.id} className="tableRow">
                                            <TableCell>{ride.title}</TableCell>
                                            <TableCell>{getUserName(ride.userId)}</TableCell>
                                            <TableCell>{ride.activeTime.toDate().toLocaleString('en-GB')}</TableCell>
                                            <TableCell>{ride.createdBy}</TableCell>
                                            <TableCell>{getCityNameByPlate(ride.city)}</TableCell>
                                            <TableCell>{ride.address}</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => {handleOpenUpdate(ride.id,ride.city,ride.userId)}}
                                                            color='primary' size='small'>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => {handleDelete(ride.id)}}
                                                            color='error' size='small'>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                                    
                            </Table>
                            <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={rides.filter(ride => 
                                          (ride.title.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
                                          ride.address.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) || 
                                          ride.userId.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))) 
                                          && (cityName === "" || cityName === null || ride.city === getPlateNo())
                                          
                                      ).length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(event, newPage) => setPage(newPage)}
                                        labelRowsPerPage="Sayfa başına satır:"
                                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                                    />
                        </React.Fragment>
                    </Paper>
                </Grid>              
            </Grid>
            
            {/* Update Ride Modal */}
            <Modal
                open={openUpdate}
                onClose={handleCloseUpdate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    {/* Modal content */}
                    <IconButton onClick={handleCloseUpdate}>
                        <CloseIcon />
                    </IconButton>
                    <AddRidePage updateId= {updateId} user={userUpdate} city={cityUpdate} close={handleCloseUpdate} />
                    
                </Box>
            </Modal>
        </Container>
        
    );
}
