import {auth} from "../config/firebase"
import {signInWithEmailAndPassword} from "firebase/auth";
import {useState} from "react";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AlertComponent from './alert';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';




const defaultTheme = createTheme();

export const LogInPage= () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const signIn = async (email,password) => {
        setIsLoading(true);
        try {
            if(email !== "admin@admin.com" && email !== "ik@gmail.com"){    
                const db = getFirestore();
                const q = query(collection(db, "users"), where("email", "==", email));
                const querySnapshot = await getDocs(q);
            
                if (querySnapshot.empty) {
                    throw new Error("Kullanıcı Bulunamadı");
                } else {
                    querySnapshot.forEach((doc) => {
                        const userData = doc.data();
                        if (userData.role === "user") {
                            throw new Error("Bu sayfaya erişim yetkiniz bulunmamaktadır.");
                        }
                    });
                }
            }
            
            try {
                await signInWithEmailAndPassword(auth, email, password);
            }
            catch (err) {
                throw new Error("Şifrenizi Kontrol Ediniz.");
            }

            window.location.replace("/");
          } catch (err) {
            console.error(err);
            if(err.message.charAt(0) === 'B'){
                setError({ title: 'DİKKAT', message: err.message, severity: 'warning' });
            }
            else {
                setError({ title: 'HATA', message: err.message, severity: 'error' });
            }
        } finally {
            setIsLoading(false)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
        email: data.get('email'),
        password: data.get('password'),
        });
        signIn(data.get('email'),data.get('password'))
    };

    return (
     <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'url("https://firebasestorage.googleapis.com/v0/b/ytu-travel-app.appspot.com/o/background.png?alt=media&token=280eecc8-e0ca-45de-a487-d2ad9118865d") no-repeat center center / cover',
        }}
      >
        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs" sx={{
        }} >
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

            }}
            >
            <img src="https://firebasestorage.googleapis.com/v0/b/ytu-travel-app.appspot.com/o/logo.png?alt=media&token=73d40582-38f9-44ec-83e4-70f3e148c796" alt="logo" border="0" width="400" height="125" />
            <br/>
            <br/>

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            {/* 
            <Typography component="h1" variant="h5">
                Yönetim Ekranına Giriş Yapın
            </Typography>
            */}
            <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                autoFocus
                onFocus={()=>{setError(null)}}
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Şifre"
                type="password"
                id="password"
                autoComplete="current-password"
                onFocus={()=>{setError(null)}}
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading} 
                >
                GİRİŞ YAP
                </Button>
            </Box>
            {error && <AlertComponent {...error} />}
            
            {/*TODO: REMOVE BUTTON BEFORE LAUNCH  
            <Button
                type="submit"
                //fullWidth
                variant="contained"
                color="error"
                sx={{ mt: 30, mb: 2 }}
                onClick={() => {signIn("deneme@gmail.com","123456")}}
                >
                DEBUG LOGIN - REMOVE BEFORE LAUNCH
            </Button>
            <Button
                type="submit"
                //fullWidth
                variant="contained"
                color="error"
                sx={{ mt: 1, mb: 2 }}
                onClick={() => {signIn("admin@admin.com","admin123")}}
                >
                ADMIN LOGIN - REMOVE BEFORE LAUNCH
            </Button>*/}
            </Box>
        </Container>
        </ThemeProvider>
     </Box>
    );
}